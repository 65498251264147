<template>
    <section class="user-details">
        <tool-bar>
            Details of {{user.email }}
        </tool-bar>
    </section>
</template>

<script>
    import ToolBar from '../layouts/ToolBar'

    export default {
        name: 'UserDetails',
        components: {ToolBar},
        data() {
            return {}
        },
        computed: {
            user() {
                return this.$store.state.users.users.find((user) => user._id === this.$route.params.id)
            },

            orders() {
                return this.$store.state.shop.orders.filter(order => order.owner === this.$route.params.id)
            },

            bookings() {
                return this.$store.state.salon.bookings.filter(booking => booking.owner === this.$route.params.id)
            }
        },
        methods: {}
    }
</script>

<style lang="scss" scoped>
    .user-details {

    }
</style>
